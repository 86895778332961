<template lang="pug">
  .subscriptions-page
    .header
      h1.nio-h1.text-primary-darker Data Subscriptions
      NioButton.new-subscription(
        normal-primary 
        @click="newSubscription"
      ) New Subscription
    .app-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .no-subscriptions(
      v-else-if="subscriptions && subscriptions.length === 0"
    )
      NioIconFramer(
        icon-name="display-list"
      )
      h3.nio-h3.text-primary-darker You have no data subscriptions
      NioButton(
        normal-secondary
        @click="seeHow"
      ) See how data subscriptions work
    .subscriptions(
      v-else
    )
      NioSlatTable(
        v-if="columns && subscriptions"
        :items="subscriptions"
        :columns="columns"
        :sort-options="sortOptions"
        :initial-items-per-page="10"
        :searchable-props="['name', 'description']"
        action="expand"
        search-sort-header
        pagination
        @paginationPageChanged="pageChanged"
      )
        template(v-slot:item-expanded="slotProps")
         NioBuyerStudioSubscription(
            v-if="openApiBaseUrl && openApiToken"
            :subscription="slotProps.item"
            :attributes="attributes"
            :seller-companies="sellerCompanies"
            :datasets="datasets"
            :open-api-token="openApiToken"
            :open-api-base-url="openApiBaseUrl"
            @deactivateSubscription="deactivateSubscription($event)"
          )
    NioDialog(
      v-model="confirmDeactivateDialog" 
    )
      ConfirmDeactivateDialog(
        @cancel="confirmDeactivateDialog = false"
        @confirm="confirmDeactivateSubscription"
      )        
</template>

<script>

import { sortOptions } from './sortOptions'
import ConfirmDeactivateDialog from './ConfirmDeactivateDialog'
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import moment from 'moment'
import { replacePropertyRefs } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'
import { getSubscriptions, getAttributes, getDatasets, getSellerCompanies } from '@/shared/fetchOperations' 

export default {
  components: { ConfirmDeactivateDialog },
  data: () => ({
    subscriptions: null,
    attributes: null,
    sellerCompanies: null,
    loading: true,
    columns: null,
    sortOptions: sortOptions,
    confirmDeactivateDialog: false,
    deactivateSubscriptionId: null,
    openApiToken: null,
    openApiBaseUrl: null,
    datasets: null
  }),	
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    async openApiInit(token, baseURL) {
      this.openApiToken = token
      this.openApiBaseUrl = baseURL
      const [
        subscriptions,
        attributes, 
        sellerCompanies,
        datasets
      ] = await Promise.all([
        getSubscriptions(this.$nioOpenApi),
        getAttributes(this.$nioOpenApi), 
        getSellerCompanies(this.$nioOpenApi),
        getDatasets(this.$nioOpenApi)
      ])
      this.subscriptions = subscriptions
      this.attributes = attributes.map(attribute => {
        return {
          ...replacePropertyRefs(attribute, attributes)
        }
      })
      this.datasets = datasets
      this.sellerCompanies = sellerCompanies
      this.makeColumns()
      this.loading = false
    },
    newSubscription() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'new'
      },"*")
    },
    seeHow() {
      window.open('https://kb.narrative.io/data-studio-for-buyers', '_blank')
    },
    computeImage(item) {
      return 'https://cdn.narrative.io/data-studio/images/narrative-placeholder-primary.svg'
    },
    computeOrderNumber(item) {
      return `#${item.id}`
    },
    computeCreated(item) {
      return moment(item.created_at).format('MMM D, YYYY')
    },
    capitalize(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    computeStatus(item) {
      return this.capitalize(item.status)
    },  
    makeColumns() {
      this.columns = [
        {
          name: "slat",
          props: {
            image: this.computeImage,
            title: 'name',
            subtitle: this.computeOrderNumber
          }
        },
        { 
          name: 'status',
          label: 'Status',
          computed: this.computeStatus,
          addItemAsClass: true
        },
        {
          name: "created",
          label: "Created",
          computed: this.computeCreated
        }
      ]
    },
    upgrade() {
      window.open('https://app.narrative.io/app/data-studio-for-buyers', '_blank')
    },
    deactivateSubscription(subscriptionId) {
      this.deactivateSubscriptionId = subscriptionId
      this.confirmDeactivateDialog = true
    },
    async confirmDeactivateSubscription() {
      this.confirmDeactivateDialog = false
      this.pageChanged()
      this.loading = true
      await this.$nioOpenApi.delete(`/data-shops/subscriptions/${this.deactivateSubscriptionId}`)
      this.subscriptions = await getSubscriptions(this.$nioOpenApi)
      this.loading = false
    }, 
    pageChanged() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
    }
  } 
};
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/slat/_slat-group"

.subscriptions-page
  background-color: $c-white
  padding: 1.5rem
  .header
    display: flex
    justify-content: flex-start
    align-items: flex-end
    position: relative
    margin-bottom: 2.2rem
    height: 2.25rem
    h1
      line-height: 1.75rem
    .nio-button
      position: absolute
      right: 0rem
  .no-subscriptions, .free-version-footer
    padding: 9.6875rem 1.5rem 11.1875rem 1.5rem
    background-color: $c-canvas
    border: 0.0625rem solid $c-primary-lighter
    border-radius: 0.75rem
    display: flex
    flex-direction: column
    align-items: center
    .nio-icon-framer
      margin-bottom: 1rem
    h3
      margin-bottom: 0.5rem
    p    
      margin-bottom: 2.5rem
  ::v-deep .column-status.value-Stopped .nio-table-value
    color: $c-error  
  ::v-deep .nio-slat-title
    max-width: 31.25rem
    white-space: normal !important
  ::v-deep .expanded-row
    background-color: $c-canvas
    .v-expansion-panel-header
      background-color: $c-white
  ::v-deep .applied-filters
    .v-expansion-panel--active
      .nio-filter-header.summary
        background-color: white !important
      .nio-tags-field
        .nio-pill-content
          max-width: 760px
          text-overflow: ellipsis
          overflow: hidden
          white-space: nowrap
          display: block
</style>