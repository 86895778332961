export const sortOptions = [
  {
    label: 'Date [newest - oldest]',
    value: {
      itemProp: 'created_at',
      propType: 'Date',
      order: 'descending'
    }
  },
  {
    label: 'Date [oldest - newest]',
    value: {
      itemProp: 'created_at',
      propType: 'Date',
      order: 'asscending'
    }	
  },
  {
    label: 'Alphabetic [A-Z]',
    value: {
      itemProp: 'name',
      propType: 'String',
      order: 'ascending'
    }	
  },
  {
    label: 'Alphabetic [Z-A]',
    value: {
      itemProp: 'name',
      propType: 'String',
      order: 'descending'
    }	
  }
]